import { TimePicker as TimePickerAnt } from 'antd';
import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import { useCallback } from 'react';
import { Size } from '../../types/component.type';
import { Form, FormItemType } from '../form/form.component';
import { Write } from '../write/write.component';
import './durationpicker.component.scss';

dayjs.extend(dayjsDuration);
dayjs.extend(utc);

class Static {
  static durationToDayjs = (duration: number): dayjs.Dayjs => {
    return dayjs.utc(0).add(duration, 'milliseconds');
  };

  static daysjsToDuration = (duration: dayjs.Dayjs): number => {
    return new Date(dayjs(duration).toISOString()).getTime();
  };
}

export declare namespace DurationPickerType {
  type Props = {
    className?: string;
    handleEvent?: {
      time?: (value: number | null) => void;
    };
    data?: {
      defaultValue?: number;
      value?: number;
    };
    config?: {
      presets?: Array<DurationPickerType.Config.Preset<number>>;
      width?: Extract<
        Size,
        | 'initial'
        | 'xsmall'
        | 'small'
        | 'xmedium'
        | 'medium'
        | 'large'
        | 'xlarge'
        | 'full'
      >;
      height?: Extract<
        Size,
        | 'initial'
        | 'xsmall'
        | 'small'
        | 'xmedium'
        | 'medium'
        | 'large'
        | 'xlarge'
        | 'full'
      >;
      disabled?: boolean;
      format?: DurationPickerType.Config.Format;
      clear?: boolean;
      min?: DurationPickerType.Config.Min;
      max?: DurationPickerType.Config.Max;
    };
  };

  namespace Config {
    type Preset<T> = { label: string; value: T };
    type Format = 'HH:mm' | 'HH:mm:ss';
    type Min = string | null;
    type Max = string | null;
  }
}

export const DurationPicker = ({
  data: { defaultValue, value } = {},
  handleEvent: { time } = {},
  config: {
    width,
    height,
    disabled = false,
    presets = [],
    format = 'HH:mm',
    clear = false,
    min,
    max,
  } = {},
  className = '',
  value: valueFormItem,
  onChange: onChangeFormItem,
  ...formItem
}: FormItemType.Legacy<DurationPickerType.Props>) => {
  const onChangeData = useCallback((data: dayjs.Dayjs | null) => {
    time?.(data ? Static.daysjsToDuration(data) : null);
    onChangeFormItem?.(data ? Static.daysjsToDuration(data) : null);
  }, []);

  const presetFormater = useCallback(
    (data: Array<DurationPickerType.Config.Preset<number>>) =>
      data.map(({ label, value }) => ({
        label: (
          <Write
            data={{
              item: label,
            }}
            config={{
              mode: 'value-small',
            }}
          ></Write>
        ),
        value: Static.durationToDayjs(value),
      })),
    [],
  );

  return (
    <Form.Context.Consumer>
      {(form) => {
        console.log({ form, value, defaultValue, valueFormItem });

        return (
          <>
            <TimePickerAnt
              minDate={min ? dayjs(min) : undefined}
              maxDate={max ? dayjs(max) : undefined}
              className={`
                formItemTargetWidth--${form.width || width || 'medium'}
                durationpicker
                durationpicker--width--${form.width || width || 'medium'}
                durationpicker--height--${form.height || height || 'medium'}
                ${className}
              `}
              onChange={(date: dayjs.Dayjs | null) => {
                onChangeData(date);
              }}
              defaultValue={
                defaultValue
                  ? Static.durationToDayjs(defaultValue)
                  : dayjs.utc(0)
              }
              value={
                value || valueFormItem
                  ? Static.durationToDayjs(value || valueFormItem)
                  : null
              }
              disabled={disabled}
              format={format}
              allowClear={clear}
              presets={
                presets && presets.length > 0
                  ? presetFormater(presets)
                  : undefined
              }
              showNow={false}
              {...formItem}
            />
          </>
        );
      }}
    </Form.Context.Consumer>
  );
};

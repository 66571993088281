import { useNavigate } from '@gimlite/router';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const formattedValue = (
  key: string,
  value: string,
  paramsFormatted?: Record<string, 'string' | 'boolean' | 'number'>,
): string | number | boolean => {
  let targetFormat = 'string';

  if (paramsFormatted && paramsFormatted?.[key]) {
    targetFormat = paramsFormatted?.[key];
  } else if (typeof value === 'string') {
    if (/^\d+.?\d*$/.test(value)) {
      targetFormat = 'number';
    } else if (['true', 'false'].includes(value)) {
      targetFormat = 'boolean';
    }
  }

  switch (targetFormat) {
    case 'number':
      return toNumber(value);
    case 'boolean':
      return toBoolean(value);
    default:
      return value;
  }
};

type useMyUrlType = {
  paramsFormatted?: Record<string, 'string' | 'boolean' | 'number'>;
  // defaultValue?: Object;
};

export function useMyUrl(params?: useMyUrlType) {
  // const defaultValue = params?.defaultValue || {};
  const paramsFormatted = params?.paramsFormatted || {};
  const location = useLocation();
  const navigate = useNavigate();

  const buildParamsUrl = (params: any) => {
    const url = new URLSearchParams();
    Object.entries(params).map(([key, value]: any) => {
      if (!value && value !== 0 && value !== null) return;
      if (Array.isArray(value)) {
        value.forEach((value) => url.append(key, value));
      } else {
        url.set(key, value);
      }
    });

    return url.toString();
  };

  const setParamsUrl = (params: any) => {
    navigate(`?${buildParamsUrl(params)}`);
  };

  const getParamsUrl = useMemo(() => {
    const url = new URLSearchParams(location.search);
    const paramsEntries: any = Array.from(url.entries()).reduce(
      (acc: any, [key, value]) => {
        const valueFormatted: unknown = formattedValue(
          key,
          value,
          paramsFormatted,
        );

        if (acc[key]) {
          const values = Array.isArray(acc[key]) ? acc[key] : [acc[key]];
          values.push(valueFormatted);
          return { ...acc, [key]: values };
        }

        return { ...acc, [key]: valueFormatted };
      },
      {},
    );

    return paramsEntries;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const getParamsUrlString = useMemo(() => {
    const params = new URLSearchParams(location.search).toString();

    return params;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const clearParamsUrl = async () => {
    return new Promise((resolve) => {
      location.search = '';
      navigate('');
      resolve(true);
    });
  };

  const removeParamsUrl = async (key: string) => {
    return new Promise((resolve) => {
      const params = new URLSearchParams(location.search);
      params.delete(key);
      navigate(`?${params.toString()}`);
      resolve(true);
    });
  };

  return {
    getParamsUrl,
    getParamsUrlString,
    setParamsUrl,
    clearParamsUrl,
    buildParamsUrl,
    removeParamsUrl,
  } as any;
}

const toBoolean = (value: string): boolean => {
  return value === 'true';
};

const toNumber = (value: string): number => {
  return parseFloat(value);
};

import { requestGQL } from '@gimlite/watermelon/functions/request.function';
import { assign, createMachine, interpret } from 'xstate';
import { session as sessionGQL } from './gql/session.gql';
import { sessions as sessionsGQL } from './gql/sessions.gql';
import { updateSessionGql } from './gql/update-session.gql';
import {
  read as readSessionsRender,
  search as searchSessionsRender,
  update as updateSessionsRender,
} from './projection/session.render';

const initialContext = {};

const machine = createMachine(
  {
    id: 'vao',
    initial: 'off',
    predictableActionArguments: true,
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: {
            target: 'idle',
          },
        },
      },
      idle: {
        on: {
          KILL: {
            target: 'off',
            actions: assign({
              searchSessions: () => undefined,
              readSession: () => undefined,
              error: () => undefined,
            }),
          },
          SEARCH_SESSIONS: { target: 'searchSessions' },
          READ_SESSION: { target: 'readSession' },
          UPDATE_SESSION: { target: 'updateSession' },
        },
      },
      searchSessions: {
        invoke: {
          id: 'searchSessions',
          src: 'searchSessions',
          onDone: {
            target: 'idle',
            actions: assign({
              searchSessions: (_, { data }) => data,
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({ error: (_, { data }) => data }),
          },
        },
      },
      readSession: {
        invoke: {
          id: 'readSession',
          src: 'readSession',
          onDone: {
            target: 'idle',
            actions: assign({
              readSession: (_, { data }) => data,
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({ error: (_, { data }) => data }),
          },
        },
      },
      updateSession: {
        invoke: {
          id: 'updateSession',
          src: 'updateSession',
          onDone: {
            target: 'idle',
            actions: ['resetCurrentSession', 'updateSession'],
          },
          onError: {
            target: 'idle',
            actions: assign({
              error: (_, { data }) => data,
            }),
          },
        },
      },
    },
    on: {
      RESET_CURRENT_SESSION: {
        actions: 'resetCurrentSession',
      },
      LOGOUT: { actions: assign(initialContext) },
    },
  },
  {
    actions: {
      resetCurrentSession: assign({
        readSession: () => undefined,
      }),
      updateSession: assign({
        updateSession: (_, { data }) => data?._id,
      }),
    },
    services: {
      searchSessions: (context, params) =>
        requestGQL({
          params: {
            ...params,
            order: '-endDate',
            limit: 50,
            minUncoveredDuration: params?.minUncoveredDuration
              ? Math.floor(params.minUncoveredDuration / (1000 * 60))
              : undefined,
          },
          gql: sessionsGQL,
          render: (res) => searchSessionsRender(context, res),
        }),
      readSession: (context, params) =>
        requestGQL({
          params,
          gql: sessionGQL,
          render: (res) => readSessionsRender(context, res),
        }),
      updateSession: (context, params) =>
        requestGQL({
          params,
          gql: updateSessionGql,
          render: (res) => updateSessionsRender(context, res),
        }),
    },
  },
);

export const vaoService = interpret(machine).start();
